import React from 'react'

const LoadComment = () => {
     return (
          <div className="pl-2 w-full">
               <div className="flex items-center w-full">
                    <svg className="w-10 h-10 me-3 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                         <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                    </svg>
                    <div className='w-full'>
                         <div className="w-1/12 h-2.5 bg-gray-400 rounded-full mb-2"></div>
                         <div className='flex space-x-2 w-60'>
                         <div className="w-1/2 h-2 bg-gray-400 rounded-full"></div>
                         <div className="w-1/2 h-2 bg-gray-400 rounded-full"></div>
                         </div>
                    </div>
               </div>
          </div>
     )
}

export default LoadComment